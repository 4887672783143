import { useState, useEffect, Fragment, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const  LazyLoadComponent = props => {
	const { image, title, alt, blur = '', className = '', containVideoOverlay = false } = props;
	const [loaded, setLoaded] = useState(false);
	const [windowLoaded, setWindowLoaded] = useState(false);

	useEffect(() => {
		if (typeof window !== "undefined") {
			if (!windowLoaded) {
				setWindowLoaded(true);
			}
		}
	}, []);

	const onLoad = useCallback(() => {
		setLoaded(true);
	}, []);

	useEffect(() => {
		if (windowLoaded && image) {
			// Preload IMG
			const img = new Image();

			img.src = "";
			img.addEventListener('load', onLoad, false)
			img.src = image && image.url ? image.url : 'https://cdn.honda.co.za/main-03/assets/images/Honda-Lazy_Load_Placeholder.png';

			return () => img.removeEventListener('load', onLoad);
		}
	}, [windowLoaded, image])

	if (!image) {
		return null
	}

	return (
		<div className={`lazyLoad ${className}`}>
			{typeof image.url !== 'undefined' && (
				<Fragment>
					<img 
						src={image.url ? image.url : 'https://cdn.honda.co.za/main-03/assets/images/Honda-Lazy_Load_Placeholder.png'} alt={alt} title={title ? title : alt} className={`lazyLoadImg ${loaded ? 'loaded' : 'loading'}`} 
						width={image.data && image.data.width ? image.data.width : 500 } 
						height={image.data && image.data.height ? image.data.height : 290} 
					/>
					{containVideoOverlay && (
						<div className="video__overlay">
							<FontAwesomeIcon
								icon={["far", "play-circle"]}
								size="1x"
							/>
						</div>
					)}
					{!loaded &&
						<div className={`placeholder ${loaded ? 'loaded' : 'loading'} ${blur}`}>
							<img
								src={image.lazyLoad}
								width={image.data.width}
								height={image.data.height}
								alt={title ? title : "Honda South Africa"}
							/>
						</div>
					}
				</Fragment>
			)}
		</div>
	);
};

export default LazyLoadComponent;